import React from 'react'
import LayoutDefault from "../layouts";
import * as classes from '../sass/style.module.scss'
import loginImg from '../sass/img/images/login.png'
import homeImg from '../sass/img/images/home.png'
import createNew from '../sass/img/images/createNew.png'
import portsImg from '../sass/img/images/ports.png'
import successImg from '../sass/img/images/success.png'
import addedStream from '../sass/img/images/addedStream.png'
import stream from '../sass/img/images/stream.png'
import DocInfoCode from "../components/SyntaxHighlighter";
import { L10nProvider } from '@logdoc/l10n';
const Ins = () => {
    return (
        <L10nProvider>
            <InstructionsLogDoc />
        </L10nProvider>
    )
}

export const InstructionsLogDoc = () => {
    return (
        <LayoutDefault>
            <section className={classes.sec}>
                <div className={classes.secBreadcrumbs}>
                    <span>Начало работы</span>
                    <span>/</span>
                    <span>первый запуск</span>
                </div>
                <h3 className={classes.secSubtitle}>
                    Быстрая проверка
                </h3>
                <div className={classes.secBlock}>
                    <p>Первый шаг необходим только для версии Community. Версия Portable не требует аутентификации.</p>
                    <img className={classes.image} src={loginImg}/>
                    <div className={classes.secDescription}>
                        <p>
                            Сразу после успешной установки LogDoc автоматически создаёт пользователя с правами
                            администратора: <span className={classes.textOrange}>admin</span> / <span
                            className={classes.textOrange}>nimda</span> - используйте эти резвизиты для первого входа.
                        </p>
                    </div>
                </div>
                <div className={classes.secBlock}>
                    <img className={classes.image} src={homeImg}/>
                    <div className={classes.secDescription}>
                        <p>Если всё прошло хорошо, то после нажатия на кнопку "Войти" вы должны увидеть вот такой
                            главный экран LogDoc, здесь будут появляться все сохранённые выборки.<br/>
                        </p>
                        <p>
                            Самый быстрый способ проверить работу LogDoc - это попробовать получить какие-нибудь события из демона Syslog.<br/>
                            Для этого нам понадобится открыть порт для прослушивания и транслировать туда логи из демона.
                        </p>
                        <p>На главном экране, в верхнем меню справа, кликните "Админ", затем, на появившейся странице, кликните на раздел "Порты"</p>
                        <img className={classes.image} src={portsImg}/>
                        <p>
                            Нас интересует идентификатор <strong>Logdoc-Syslog-Udp-Handler-UDP</strong> или <strong>Logdoc-Syslog-Tcp-Handler-TCP</strong>.<br/>
                            Укажите в поле рядом с ним любой незанятый порт, например 5566, и переведите галочку справа в положение "вкл".
                        </p>
                        <p>Если LogDoc показал зелёное уведомление о том, что слушатель запущен - значит вы всё сделали правильно, можно вернуться на главный экран ("Логи" в верхнем меню).</p>
                        <img className={classes.image} src={homeImg}/>
                        <p>
                            Kнопка <strong>+</strong> слева-вверху позволяет создавать новые выборки, нажмите на неё.
                        </p>
                        <img className={classes.image} src={createNew}/>
                        <p>В появившейся форме выбираем тип "Поток", вводим любое понравившееся название (например "Все логи") и нажимаем "Сохранить".</p>
                        <img className={classes.image} src={addedStream}/>
                        <p>Теперь у нас есть выборка, которую можно использовать. Нажмите на значок воспроизведения слева от названия выборки.</p>
                        <p>Вы должны видеть пустой экран с названием потока, но без событий. Всё верно, syslog пока что нам ничего не транслирует, нужно сообщить ему о том, что мы ожидаем логи.</p>
                    </div>
                    <div className={classes.secDescription}>
                        <p>
                            <ul>
                                <li>В конфигурационном файле syslogd (rsyslogd) укажите настройки транслирования для tcp (если вы открыли порт для TCP):
                                    <DocInfoCode text={`*.*     @localhost:5566`} language={`conf`}/>
                                </li>
                                <li>или для udp:
                                    <DocInfoCode text={`*.*     @@localhost:5566`} language={`conf`}/>
                                </li>
                                <li>перезапуститe демон:
                                    <DocInfoCode text={`sudo service rsyslog restart`} language={`bash`}/>
                                </li>
                            </ul>
                        </p>
                        <p>Если вы всё сделали верно, то теперь на экране нашей выборки вы должны увидеть системные события наподобие таких:</p>
                        <img className={classes.image} src={stream}/>
                    </div>
                    <div className={classes.secDescription}>
                        <p>Поздравляем, вы успешно запустили LogDoc!</p>
                    </div>
                </div>
            </section>
        </LayoutDefault>
    );
};

export default Ins;
